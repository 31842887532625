import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mainSite: 'digitum'
}

const siteLogic = createSlice({
    name: 'siteChoose',
    initialState,
    reducers: {
        setMainSite: (state, action) => {
            state.mainSite = action.payload
        }
    }
});

export const {setMainSite} = siteLogic.actions;
export default siteLogic.reducer;